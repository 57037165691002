import { required, minLength } from 'vuelidate/lib/validators';

const smsApproveValidationMixin = {
  validations: {
    smsCode: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {},
};

export default smsApproveValidationMixin;
