<template>
  <v-dialog v-model="dialog" persistent>
    <v-card class="grey lighten-2" light>
      <v-card-text class="text-center">
        <v-row>
          <v-col>
            <v-icon v-text="'far fa-envelope-open'" color="primary" class="mt-10" size="100"></v-icon>
          </v-col>
        </v-row>
        <template v-if="smsCodeValidationReady">
          <v-row>
            <v-col class="text-h5"> 2 dakika geçerli doğrulama kodunuz cep telefonunuza gönderildi </v-col>
          </v-row>
          <template v-if="countDown">
            <v-row class="mt-5">
              <v-col class="text-h4"> Kalan Süre </v-col>
            </v-row>
            <v-row>
              <v-col class="text-h1 font-weight-bold count-down">
                {{ countDown }}
              </v-col>
            </v-row>
          </template>
          <v-row class="mt-2">
            <v-col class="text-subtitle-2"> Lütfen cep telefonunuza gönderilen 6 haneli şifreyi giriniz </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col sm="12" md="5">
              <v-otp-input
                length="6"
                v-model="$v.smsCode.$model"
                @finish="validateSmsCode"
                type="number"
                ref="smsCode"
              ></v-otp-input>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col class="text-h5"> Gönderilen kodun geçerlilik süresi dolmuştur. </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                :loading="sendPreloader"
                large
                shaped
                color="primary"
                @click="reSend"
                v-text="'Yeni Kod Gönder'"
              ></v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { wizardStep } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import { APPROVE_VALIDATON, RESEND_SMS_CODE, CHANGE_WIZARD_STEP } from '@/store/modules/carWizard.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { validationMixin } from 'vuelidate';
import smsApproveValidationMixin from '@/mixins/validations/carWizard/smsApproveValidationMixin';

export default {
  name: 'SmsApprove',
  components: {},
  mixins: [validationMixin, smsApproveValidationMixin],
  data() {
    return {
      wizardStep,
      dialog: false,
      smsCode: null,
      timeOut: null,
      interval: null,
      smsCodeIsExpired: false,
      validatePreloader: false,
      sendPreloader: false,
    };
  },
  computed: {
    ...mapGetters(['getSmsCodeExpireMinute']),
    countDown() {
      return this.timeOut;
    },
    smsCodeValidationReady() {
      return !this.smsCodeIsExpired;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      approveValidation: APPROVE_VALIDATON,
      reSendSmsValidationCode: RESEND_SMS_CODE,
      setMessageDialog: SET_MESSAGE_DIALOG,
      disposeMessageDialog: DISPOSE_MESSAGE,
      changeWizardStep: CHANGE_WIZARD_STEP,
    }),
    async startCountDown() {
      return new Promise((resolve) => {
        if (this.getSmsCodeExpireMinute) {
          this.smsCodeIsExpired = false;

          const eventTime = this.$moment().add(this.getSmsCodeExpireMinute, 'minutes').unix();
          const currentTime = this.$moment().unix();
          const diffTime = eventTime - currentTime;
          let duration = this.$moment.duration(diffTime * 1000, 'milliseconds');
          this.timeOut = this.$moment(duration._data).format('mm:ss');

          const interval = 1000;
          this.interval = setInterval(() => {
            duration = this.$moment.duration(duration - interval, 'milliseconds');

            this.timeOut = this.$moment(duration._data).format('mm:ss');
            if (duration._milliseconds <= 0) {
              clearInterval(this.interval);
              this.smsCodeIsExpired = true;
            }
          }, interval);

          resolve();
        }
      });
    },
    validateSmsCode() {
      this.validatePreloader = true;
      this.approveValidation(this.smsCode)
        .then((response) => {
          if (response) {
            clearInterval(this.interval);
            this.interval = null;
            this.dialog = false;

            this.$emit('login-user', this.smsCode);

            this.changeWizardStep(wizardStep.OFFER);
          }
        })
        .catch((error) => {
          let message;
          if (error.responseCode === 9003) message = 'Gönderilen sms kodu hatalıdır.';
          else message = 'Gönderilen sms kodunun geçerlilik süresi dolmuştur.';

          this.setMessageDialog({
            messageType: 'error',
            subTitle: 'Hatalı!',
            text: message,
          });
        })
        .finally(() => {
          this.smsCode = null;
          this.validatePreloader = false;
          this.$v.$reset();
        });
    },
    reSend() {
      this.sendPreloader = true;
      this.reSendSmsValidationCode().finally(() => {
        this.sendPreloader = false;
        this.smsCode = null;
        this.$v.$reset();
      });
    },
  },
  watch: {
    getSmsCodeExpireMinute: {
      async handler(value) {
        if (value) {
          await this.startCountDown();
          this.dialog = true;

          setTimeout(() => {
            this.$refs.smsCode.focus();
          }, 500);
        } else {
          clearInterval(this.interval);
          this.interval = null;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
:deep() {
  .sms-code {
    input {
      text-align: center;
      font-size: 20px !important;
      letter-spacing: 0.5em !important;
    }
  }
}
</style>
