<template>
  <v-card
    color="transparent"
    flat
    v-if="getWizardStep === wizardStep.CONTACT || getWizardStep === wizardStep.SMS_APPROVE"
  >
    <SmsApprove @login-user="loginUser" />
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-subheader>Cep Telefonu *</v-subheader>
          <v-text-field-simplemask
            v-model="$v.contact.phoneNumber.$model"
            v-bind:label="'Cep telefonunuzu girin'"
            v-bind:properties="{
              inputmode: 'numeric',
              prefix: '+90',
              solo: true,
              dense: true,
              flat: true,
              class: 'rounded-lg',
              errorMessages: phoneNumberErrors(),
              hideDetails: 'auto',
            }"
            v-bind:options="{
              inputMask: '(###)-###-##-##',
              outputMask: '##########',
              empty: null,
              applyAfter: false,
              alphanumeric: true,
              lowerCase: false,
            }"
          />
        </v-col>
        <!-- <v-col cols="12">
          <v-subheader>E-Mail Adresi</v-subheader>
          <v-text-field
            inputmode="email"
            label="E-Mail Adresinizi girin"
            solo
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            v-model="$v.contact.email.$model"
            :error-messages="emailErrors()"
          ></v-text-field>
        </v-col> -->
        <!-- <v-col cols="12">
          <v-subheader>İsim - Soyisim *</v-subheader>
          <v-text-field
            label="İsim ve soyisim girin"
            solo
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            v-model="$v.contact.name.$model"
            :error-messages="nameErrors()"
          ></v-text-field>
        </v-col> -->
        <!-- <v-col cols="12">
          <v-subheader>Şehir *</v-subheader>
          <v-select
            item-text="citName"
            item-value="citId"
            label="Şehrinizi seçin"
            solo
            hide-details="auto"
            flat
            dense
            class="rounded-lg"
            v-model="contact.cityId"
            :items="getCities"
          ></v-select>
        </v-col> -->
        <v-col cols="12">
          <v-checkbox
            color="primary"
            hide-details="auto"
            :error-messages="kvkkApprovedErrors()"
            v-model="$v.contact.kvkkApproved.$model"
          >
            <template v-slot:label>
              <div class="caption">
                <span class="caption font-weight-bold">
                  {{ company.cmpName }}
                  <a class="primary--text" href="#" target="_blank" @click.stop>KVKK Aydınlatma Metnini</a>
                  okuyup anladığımı, verilerimin işlenmesi, aktarılması ve kullanılmasına bu çerçevede açıkça rıza
                  gösterdiğimi beyan ve kabul ederim.
                </span>
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox color="primary" hide-details="auto" v-model="contact.contactApproved">
            <template v-slot:label>
              <div class="caption">
                <span class="caption font-weight-bold">
                  {{ company.cmpName }} ürün/hizmet teklifleri için iletişim kurulmasını kabul ediyorum.
                </span>
              </div>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" class="d-flex flex-column align-center justify-center pt-5">
          <vue-recaptcha
            ref="recaptcha"
            @verify="onVerifyCaptcha"
            @expired="onExpiredCaptcha"
            :sitekey="captchaSiteKey"
            :loadRecaptchaScript="true"
          >
          </vue-recaptcha>
          <div class="error--text" v-if="$v.captchaValid.$error">Lütfen robot olmadığınızı doğrulayın!</div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-center py-5">
      <v-btn
        shaped
        outlined
        color="primary"
        class="px-5 rounded-lg text-capitalize"
        v-text="'Geri'"
        @click="returnBack"
      ></v-btn>
      <v-btn
        :loading="loading"
        shaped
        color="primary"
        class="px-5 rounded-lg text-capitalize"
        v-text="'Telefon ile doğrula'"
        @click="continueProcess"
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import wizardMixin from '@/mixins/wizardMixin';
import VueRecaptcha from 'vue-recaptcha';
import { wizardStep, carPartType, issueType } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import { SAVE_CONTACT, CHANGE_WIZARD_STEP } from '@/store/modules/carWizard.module';
import { LOGIN } from '@/store/modules/auth.module';
import SmsApprove from '@/views/wizard/contact/SmsApprove.vue';
import { validationMixin } from 'vuelidate';
import contactValidationMixin from '@/mixins/validations/carWizard/contactValidationMixin';

export default {
  name: 'ContactStep',
  mixins: [validationMixin, contactValidationMixin, wizardMixin],
  components: {
    SmsApprove,
    VueRecaptcha,
  },
  data() {
    return {
      wizardStep,
      carPartType,
      issueType,
      loading: false,
      captchaValid: false,
      contact: {
        phoneNumber: null,
        email: null,
        name: null,
        cityId: null,
        kvkkApproved: false,
        contactApproved: false,
      },
      timeOut: null,
      sitekey: '6Lf4bUEdAAAAAEhSyB4thntPD6IsW2xE5d_ewDzx',
    };
  },
  computed: {
    ...mapGetters(['getCities', 'company']),
    smsTimeOut() {
      return this.timeOut;
    },
    captchaSiteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    },
  },
  created() {
    this.$nextTick(() => {
      // this.setCityList();
    });
  },
  methods: {
    ...mapActions({
      login: LOGIN,
      changeWizardStep: CHANGE_WIZARD_STEP,
      saveContactAndSendSmsValidationCode: SAVE_CONTACT,
      // setCityList: SET_CITY_LIST,
    }),
    onExpiredCaptcha() {
      this.captchaValid = false;
      this.$refs.recaptcha.reset();
    },
    onVerifyCaptcha() {
      this.captchaValid = true;
    },
    loginUser(smsCode) {
      const payload = {
        userPassword: smsCode,
        userName: `90${this.contact.phoneNumber}`,
      };
      this.login(payload).then(() => {
        this.$router.push({ name: 'wizardOffer' });
      });
    },
    continueProcess() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      this.saveContactAndSendSmsValidationCode(this.contact)
        .then((response) => {
          this.timeOut = response.smsCodeTimeOut;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    returnBack() {
      this.changeWizardStep(wizardStep.DAMAGE);
      this.$router.push({ name: 'wizardDamage' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
