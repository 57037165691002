/* eslint-disable no-unused-expressions */
import { required, helpers, sameAs } from 'vuelidate/lib/validators';

const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);

// const alphabeticAndSpace = helpers.regex(
//   'alphabeticAndSpace',
//   /^[A-zğüşöçİĞÜŞÖÇı]{2,}( [A-zğüşöçİĞÜŞÖÇı]{2,})+([A-zğüşöçİĞÜŞÖÇı]|[ ]?)$/,
// );

const contactValidationMixin = {
  validations: {
    captchaValid: {
      required,
      sameAs: sameAs(() => true),
    },
    contact: {
      phoneNumber: {
        required,
        phoneCheck,
      },
      // email: {
      //   email,
      // },
      // name: {
      //   alphabeticAndSpace,
      //   required,
      // },
      kvkkApproved: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    phoneNumberErrors() {
      const errors = [];
      const { $dirty, required, phoneCheck } = this.$v.contact.phoneNumber;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen telefon numaranızı girin.');
      !phoneCheck && errors.push('Hatalı telefon numarası girdiniz.');
      return errors;
    },
    emailErrors() {
      const errors = [];
      const { $dirty, email } = this.$v.contact.email;
      if (!$dirty) return errors;
      !email && errors.push('Hatalı e-mail adresi girdiniz.');
      return errors;
    },
    // nameErrors() {
    //   const errors = [];
    //   const { $dirty, required, alphabeticAndSpace } = this.$v.contact.name;
    //   if (!$dirty) return errors;
    //   !required && errors.push('Lütfen isim ve soyisminizi girin.');
    //   !alphabeticAndSpace && errors.push('Lütfen geçerli bir ad soyad girin.');
    //   return errors;
    // },
    kvkkApprovedErrors() {
      const errors = [];
      const { $dirty, required, sameAs } = this.$v.contact.kvkkApproved;
      if (!$dirty) return errors;
      !required && errors.push('Lütfen aydınlatma metnini onaylayın.');
      !sameAs && errors.push('aydınlatma metnini onaylamanız gerekiyor.');
      return errors;
    },
  },
};

export default contactValidationMixin;
